<template>
  <div>
    <portal to="body-top">
      <customer-name title="- Update Address" />
    </portal>
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <address-form
      v-if="address.id"
      ref="address-form"
      :is-edit="isEdit"
    />
    <div v-else>
      <div v-if="!error">
        <update-skeleton />
      </div>
      <div v-else>
        <component-not-found
          :button-link="{ name: 'home-customers-addresses-list'}"
          title="Address List"
        />
      </div>
    </div>
  </div>
</template>
<script>

import { BCol, BRow, VBTooltip } from 'bootstrap-vue'
import store from '@/store'
import AddressForm from '../components/AddressForm.vue'
import config from '../addressConfig'
import updateSkeleton from '../skeletons/updateSkeleton.vue'
import CustomerName from '../../../components/CustomerName.vue'

export default {
  name: 'AddressUpdate',
  components: {
    BCol,
    BRow,
    AddressForm,
    updateSkeleton,
    CustomerName,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    address() {
      return this.$store.state[this.MODULE_NAME].addressForm
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['address-form']) {
      this.$refs['address-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  mounted() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.addressId).then(res => {
      const { data } = res.data
      const address = this.mappingFields(Object.keys(this.fields), data, {
        country_id: data.country,
        state_id: data.state,
        id: data.id,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, address)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_ADDRESS_FORM_CLONE`, address)
    }).catch(err => {
      console.log(err)
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
  },
  setup() {
    const MODULE_NAME = 'address'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style>

</style>
