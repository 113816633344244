<template>
  <b-row>
    <b-col md="6">
      <div
        v-for="field in ['street', 'country_id', 'state_id', 'reference']"
        :key="field"
        style="height: 70px"
      >
        <span class="inputLabel">{{ fields[field].label }}</span>
        <b-skeleton-img
          height="40px"
          style="border-radius: 5px"
        />
      </div>
    </b-col>
    <b-col md="6">
      <div
        v-for="field in ['city', 'zip']"
        :key="field"
        style="height: 70px"
      >
        <span class="inputLabel">{{ fields[field].label }}</span>
        <b-skeleton-img
          height="40px"
          style="border-radius: 5px"
        />
      </div>
      <div>
        <label>{{ $t('Address Type') }}</label>
        <div
          v-for="field in ['billing', 'shipping', 'account_holder']"
          :key="field"
          style="
              height: 30px;
              display: flex;
              align-item: center;
              margin-bottom: 10px;
            "
        >
          <span class="mr-4">{{ fields[field].label }}</span>
          <b-skeleton-img
            width="20px"
            height="20px"
            style="border-radius: 5px"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BSkeletonImg, BCol,
} from 'bootstrap-vue'
import config from '../addressConfig'

export default {
  name: 'UpdateSkeleton',
  components: {
    BCol,
    BRow,
    BSkeletonImg,
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
